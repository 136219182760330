jQuery(function ($) {
  $(document).ready(function () {
    $(".accordion--content--row .accordion--content--row-title").on(
      "click",
      function (e) {
        e.preventDefault();
        if (!$(this).parent().hasClass("active")) {
          $(this).parent()
            .siblings()
            .removeClass("active")
            .find(".accordion--content--row-content")
            .slideUp();
          $(this)
            .parent()
            .addClass("active")
            .find(".accordion--content--row-content")
            .slideDown();
        } else {
          $(this)
            .parent()
            .removeClass("active")
            .find(".accordion--content--row-content")
            .slideToggle();
        }
      }
    );

    // open on page load
    function openOnPageLoad() {
      $('.accordion--content.open').each(function(){
        $(this).find('.accordion--content--row:first-child .accordion--content--row-title').trigger('click');
      });
    }

    openOnPageLoad();

  });
});
